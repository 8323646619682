<template>
  <div style="padding: 20px 10px">
    <v-card style="padding: 15px">
      <div>
        <h2 class="font-weight-regular">
          Theme
        </h2>
        <v-switch
          v-model="dark"
          :hint="'This toggles dark mode of ' + app"
          insetDarkMode
          label="Enable Dark Mode"
          persistent-hint
        >
        </v-switch>
      </div>
    </v-card>
    <v-card style="padding: 15px;" class="mt-5 mb-3">
      <div>
        <h2 class="font-weight-regular">Sales Default Date Filter</h2>
        <v-card-text>
          <v-select
            :items="date_filters"
            :item-text="'text'"
            :item-value="'value'"
            name="date_filter"
            label="Default Date Filter"
            v-model="date_filter">
          </v-select>
        </v-card-text>
      </div>

      <v-btn color="primary mt-5" @click="saveDefault" style="text-transform: none;" >Set Date</v-btn>
    </v-card>
  </div>
</template>

<script>
import { APP_NAME } from '@/services/constants';
import { isDarkMode, setDarkMode } from '@/services/theme';
import {getDefaultDateFilter,setDefaultDateFilter} from '@/services/default-date-filter'

const DATE_FILTERS = [
  {value: null, text: 'Default'},
  {value: 'today', text: 'Today'},
  {value: 'yesterday', text: 'Yesterday'},
  {value: 'thisWeek', text: 'This Week'},
  {value: 'thisMonth', text: 'This Month'},
  {value: 'lastMonths_1', text: 'Since Last Month'},
  {value: 'lastMonths_2', text: 'Since Last 2 Months'},
  {value: 'lastMonths_3', text: 'Since Last 3 Months'},
  {value: 'lastMonths_6', text: 'Since Last 6 Months'}
]

export default {
  name: 'AppSettings',
  data() {
    return {
      app: APP_NAME,
      dark: false,
      date_filter: 'thisMonth',
      date_filters: DATE_FILTERS,
      not_valid_from_date: true,
      not_valid_to_date: true
    };
  },
  async mounted() {
    isDarkMode().then((v) => (this.dark = v));
    this.date_filter = await getDefaultDateFilter() || DATE_FILTERS[1]
  },
  methods: {
    async saveDefault() {
      await setDefaultDateFilter(this.date_filter)
      this.$toast('Settings saved successfully.')
    }
  },
  watch: {
    dark(val) {
      const isDark = val ? '1' : '0';
      setDarkMode(isDark);
    },
    from_date(val) {
      if(!val) this.not_valid_from_date = true
      else this.not_valid_from_date = false
    },
    to_date(val){
      if(!val) this.not_valid_to_date = true
      else this.not_valid_to_date = false
    }
  },
};
</script>

<template>
  <dashboard-layout>
    <template slot="heading">
      <v-toolbar-title class="d-none d-md-block">
        Settings
      </v-toolbar-title>
    </template>
    <template slot="content">
      <v-container>
        <v-tabs v-model="active_tab" @change="tabChanged">
          <v-tab :key="account_settings"><strong>Account Settings</strong></v-tab>
          <v-tab :key="app_settings"><strong>App Settings</strong></v-tab>
          <v-tab :key="account_bills"><strong>Account Subscription</strong></v-tab>
          <v-tab-item :key="account_settings">
            <account-settings></account-settings>
          </v-tab-item>
          <v-tab-item :key="app_settings">
            <app-settings></app-settings>
          </v-tab-item>
          <v-tab-item :key="account_bills">
            <account-bills></account-bills>
          </v-tab-item>
        </v-tabs>
      </v-container>
    </template>
  </dashboard-layout>
</template>

<script>
import AccountSettings from '@/views/dashboard/settings/AccountSettings';
import AppSettings from '@/views/dashboard/settings/AppSettings';
import AccountBills from '@/views/dashboard/settings/AccountBills';

var account_settings = 0
var app_settings = 1
var account_bills = 2
var tabs = [
  'account_settings',
  'app_settings',
  'account_bills'
]

export default {
  name: 'Settings',
  data() {
    return {
      account_settings,
      app_settings,
      account_bills,
      active_tab: account_bills
    }
  },
  components: {
    AccountSettings,
    AppSettings,
    AccountBills
  },
  async mounted() {
    this.active_tab = this[this.$route.query.tab] || this.account_settings
  },
  methods: {
    tabChanged: function () {
      var tab = tabs[this.active_tab]
      var path = this.$route.fullPath
      if (!path.includes('tab=')) {
        path = path + '?tab='
      }

      var new_path = '/#'+ path.replace(/tab=.*/i, 'tab=' + tab)
      window.history.pushState({path: new_path}, document.title, new_path);
    }
  }
};
</script>

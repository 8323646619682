<template slot="content">
  <div style="padding: 20px 10px">
    <loading-circular v-if="loading"></loading-circular>
    <v-container>
      <v-card style="padding: 10px;">
        <v-card-title>            
          Status:
          <v-badge :color="!sales_report_payment || !is_subscription_active ? 'warning' : 'success'" style="margin-top: 10px; margin-left: 10px;">
            <template v-slot:badge>
              <span class="badge-content">
                {{ !sales_report_payment ? 'Unpaid': !is_subscription_active ? 'Expired' : 'Paid'}}
              </span>
            </template>
          </v-badge>
        </v-card-title>
        <v-card-text>
          <p v-if="!sales_report_payment">Sales report data is currently limited to 1 week. Subscribe to view your sales for up to 1 year.</p>
          <p v-if="sales_report_payment && !is_subscription_active" v-html="sales_report_payment.subscription_message"></p>
          <div v-if="is_subscription_active">
            <p><a @click.prevent="PaySales" target="_blank">Renew your account subscription on or before <b>{{ renew_at }}</b> to continue using our sales report service.</a></p>
          </div>
          <v-btn color="primary" small @click.prevent="PaySales" v-if="sales_report_payment">Renew Now</v-btn>

        </v-card-text>
        <v-card-actions v-if="!sales_report_payment">
          <div class="d-block">
            <v-btn color="primary" small @click.prevent="PaySales" >Subscribe</v-btn>
            <p style="font-size: 0.875rem; font-weight: 400; color: rgba(0, 0, 0, 0.6); margin-top: 5px;">
              (Make sure that the browser pop-up blocker is disabled)
            </p>
          </div>
        </v-card-actions>
  
      </v-card>
    </v-container>
    <v-container>
      <v-card style="padding: 10px;">
        <v-card-title>
          Payment Logs
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="payment_logs"
          class="elevation-1 table"
        >
        <template v-slot:item.created_at="{item}">
          <span>{{ formatDate(item.created_at)}}</span>
        </template>
        <template v-slot:item.amount="{item}">
          <span>{{ item.currency }} {{ item.amount }}</span>
        </template>
        <template v-slot:item.status="{item}">
          <span style="color: #38AD57" v-if="item.status === 'completed'">{{ item.status.toUpperCase() }}</span>
          <span style="color: red" v-if="item.status !== 'completed'">{{ item.status.toUpperCase() }}</span>
        </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  import moment from'moment'
  import { mapActions } from 'vuex'
  import { subscribe_sales_report_payment, get_sales_report_payment } from '@/services/http/machines.js'

  export default {
    name: 'SalesReportSubscription',
    data: () => ({
      is_subscription_active: false,
      sales_report_payment: null,
      renew_at: null,
      loading: false,
      payment_logs: [],
      headers: [
        {
          text: 'Amount',
          align: 'start',
          sortable: false,
          value: 'amount'
        },
        {
          text: "Date Paid",
          value: "created_at",
          sortable: false
        },
        {
          text: "Status",
          value: "status",
          sortable: false
        }
      ]
    }),
    methods: {
      async PaySales() {
        const res = await subscribe_sales_report_payment()
        if (res.redirect_url) {
          const cart_data = encodeURIComponent(JSON.stringify(res.cart_data))
          const popupWindow = window.open(res.redirect_url + '?cart_data=' + cart_data, '_blank', 'width=600,height=800');
          if(popupWindow) {
            popupWindow.focus();
          }else {
            alert("Make sure that the browser pop-up blocker is disabled.")
          }

        } else if(res.message && res.is_paid) {
          alert(res.message)
          this.mounted()
          this.clearMachineStats()
          this.syncMachineStats({
            from_date: null,
            to_date: null
          })
        }
      },
      formatDate(date) {
        return moment(date).format('MMM DD, YYYY @ hh:mm:ss a')
      },

      ...mapActions(['clearMachineStats', 'syncMachineStats'])
      
    },
    async mounted() {
      this.loading = true
      const res = await get_sales_report_payment()
      if(res) {
        if(res.sales_report_payment) {
          this.sales_report_payment = res.sales_report_payment
          this.renew_at = moment(this.sales_report_payment.renew_at).format('ll')

          this.is_subscription_active = this.sales_report_payment && !this.sales_report_payment.subscription_message
        }
        if (res.payments){
          this.payment_logs = res.payments
        }
      } else {
        alert("Something went wrong.")
      }
      this.loading = false
    }
  }
</script>
import { Preferences } from '@capacitor/preferences';
import moment from 'moment'

const DF_KEY = 'DATE_FILTER'

export async function getFilterDates () {
  const default_filter = {
    from_date: moment().startOf('month').startOf('day'),
    to_date: moment().endOf('day')
  }

  const d = await getDefaultDateFilter()

  if (!d || typeof d != 'string') {
    return default_filter
  } else if (d === 'today') {
    return {
      from_date: moment().startOf('day'),
      to_date: moment().endOf('day')

    }
  } else if (d === 'yesterday') {
    return {
      from_date: moment().subtract(1, 'day').startOf('day'),
      to_date: moment().subtract(1, 'day').endOf('day')
    }
  } else if (d === 'thisWeek') {
    return {
      from_date: moment().startOf('isoWeek').startOf('day'),
      to_date: moment().subtract(1, 'day').endOf('day')
    }
  } else if (d === 'thisMonth') {
    return {
      from_date: moment().startOf('month').startOf('day'),
      to_date: moment().subtract(1, 'day').endOf('day')
    }
  } else if (d.startsWith('lastMonths')) {
    const m = d.match(/\d/g)
    if (m.length) {
      const month = m[0] * 1
      return {
        from_date: moment().subtract(month, 'months').startOf('month').startOf('day'),
        to_date: moment().endOf('day')
      }
    } else {
      return default_filter
    }
  } else {
    return default_filter
  }
}

export async function getDefaultDateFilter() {
  return await Preferences.get({ key: DF_KEY }).then(d => {
    return d && d.value
  })
}

export async function setDefaultDateFilter(f){
  if (!f)
    await Preferences.remove({key: DF_KEY, value: f})
  else
    await Preferences.set({key: DF_KEY, value: f})
}

<template>
	<div style="padding: 20px 10px">
		<v-card tile :loading="loading">
			<div style="padding: 30px;" elevation="2">
				<h3 class="font-weight-regular">
					Basic Information
				</h3>
				<v-form ref="form" v-model="basicInfoValid" lazy-validation>
					<v-text-field
						type="text"
						label="Username/Alias "
						v-model="username"
						:rules="usernameRules"
						:error-messages="usernameErrors"
						:loading="loading"
						required
					>
					</v-text-field>
					<v-text-field
						type="text"
						label="First Name "
						v-model="first_name"
						:rules="firstNameRules"
						:error-messages="errors.first_name"
						:loading="loading"
						required
					>
					</v-text-field>
					<v-text-field
						type="text"
						label="Last Name "
						v-model="last_name"
						:rules="lastNameRules"
						:error-messages="errors.last_name"
						:loading="loading"
						required
					>
					</v-text-field>
					<v-select
						label="Select Your Currency"
						v-model="default_currency"
						:items="currencies"
						:rules="defaultCurrencyRules"
						:error-messages="errors.default_currency"
						:loading="loading"
						required
					>
					</v-select>
					<v-select
						label="Set Your Time Zone"
						v-model="time_zone"
						:items="timezones"
						:rules="timezoneRules"
						:error-messages="errors.time_zone"
						:loading="loading"
						required
					>
					</v-select>
					<v-text-field
						v-model="address"
						type="text"
						label="Complete Address (optional)"
						:error-messages="addressErrors"
						:loading="loading"
					>
					</v-text-field>
					<v-text-field
						type="number"
						v-model="contact_number"
						label="Contact Number (optional)"
						:error-messages="contactNumberErrors"
						:loading="loading"
					>
					</v-text-field>
					<v-btn
						type="submit"
						color="primary"
						style="text-transform: none; margin-top: 10px;"
						:disabled="!basicInfoValid || loading"
						:loading="loading"
						@click.prevent="submit(1)"
					>
						Update Information
					</v-btn>
				</v-form>
			</div>
			<div style="padding: 0px 30px 30px 30px">
				<h3 class="font-weight-regular">
					Account Credentials
				</h3>
				<v-form ref="form" lazy-validation v-model="emailValid">
					<v-text-field
						v-model="email"
						:rules="emailRules"
						:error-messages="errors.email"
						:loading="loading"
						type="email"
						label="Email"
						required
					>
					</v-text-field>
					<v-btn
						:disabled="!emailValid || loading || !isEmailNew"
						style="text-transform: none; margin-top: 10px;"
						type="submit"
						color="primary"
						@click.prevent="submit(2)"
						>Update Email</v-btn
					>
				</v-form>
				<v-divider style="margin-top: 30px;"></v-divider>
				<h3 class="font-weight-regular" style="margin-top: 30px;">
					Update Password
				</h3>
				<v-form v-model="isPasswordValid" ref="form" lazy-validation>
					<v-text-field
						v-model="password"
						:rules="passwordRules"
						:error-messages="errors.password"
						:loading="loading"
						type="password"
						label="New Password"
						required
					>
					</v-text-field>
					<v-text-field
						v-model="password_confirmation"
						:error-messages="passwordConfirmationErrors"
						:loading="loading"
						type="password"
						label="Confirm New Password"
						required
					>
					</v-text-field>
					<v-btn
						:disabled="!isPasswordValid || loading"
						style="text-transform: none; margin-top:	 10px;"
						type="submit"
						color="primary"
						@click.prevent="submit(3)"
          >
            Update Password
          </v-btn
					>
				</v-form>
			</div>
		</v-card>
	</div>
</template>

<script>
import { fetchUser, updateUser } from '@/services/http/account';
import { CURRENCIES, TIMEZONES } from '@/services/constants'
import router from '@/router';

export default {
	name: 'AccountSettings',
	data: () => ({
		basicInfoValid: true,
		emailValid: true,
		isPasswordValid: true,
		loading: false,
		user: {},
		account: {},

		first_name: '',
		last_name: '',
		password: '',
		password_confirmation: '',
		email: '',
		username: '',
		contact_number: '',
		address: '',
		default_currency: '',
    time_zone: '',
		old_email: '',
		isContactValid: false,
		isUsernameValid: true,
		isEmailNew: false,
		isAddressValid: true,
		currencies: CURRENCIES,
    timezones: TIMEZONES,
		emailRules: [
			(v) => !!v || 'E-mail is required',
			(v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
		],
		usernameRules: [(v) => !!v || 'Username is required'],
		firstNameRules: [(v) => !!v || 'First name is required'],
		lastNameRules: [(v) => !!v || 'Last name is required'],
		addressRules: [(v) => !!v || 'Address name is required'],
		passwordRules: [(v) => !!v || 'Password is required'],
		defaultCurrencyRules: [(v) => !!v || 'Please set your default currency'],
		timezoneRules: [(v) => !!v || 'Please set your time zone'],

		errors: {},
		//eslint-disable-next-line
		specialChars: /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
	}),
	created() {
		this.loadInfo();
	},
	methods: {
		gotUser(u) {
			this.first_name = u.first_name;
			this.last_name = u.last_name;
			this.default_currency = u.default_currency;
      this.time_zone = u.time_zone
			this.contact_number = u.contact_number;
			this.email =  this.old_email = u.email;
			this.username = u.username;
			this.address = u.address;

			if (this.password === '') this.isPasswordValid = false;
		},

		async submit(num) {
			var newData = {};

			if(num === 1)
			{
				newData =  {
					first_name: this.first_name,
					last_name: this.last_name,
					username: this.username,
					default_currency: this.default_currency,
          time_zone: this.time_zone,
					contact_number: this.contact_number,
					address: this.address,

				}
			}else if(num === 2){
				newData = {
					email: this.email
				}
			}else if(num === 3){
				newData = {
					password: this.password,
					password_confirmation: this.password_confirmation
				}
			}

			this.errors = {};
			this.loading = true;
			updateUser(newData).then(res => {
				this.gotUser(res.data);

				if(newData.email){
					this.$toast('An confirmation email has been sent to ' + newData.email + ' in order to update your account.')
				}else if(newData.password){
					this.$toast('Please sign in using your new password.');
					setTimeout(() => {
						router.push({name: 'Logout'})
					},3000)
				}else
					this.$toast('Account updated successfully')
			})
			.catch((e) => {
				if(e.status === 422){
					this.$toast('Unable to update account due to some errors')
					this.errors = e.data;
				}else
					this.errors = e
			}).finally(() => {
				this.loading = false;
			})

		},
		async loadInfo() {
			fetchUser().then((user) => this.gotUser(user)).catch(() => this.$toast('Unable to fetch your account details'))
		}
	},
	computed: {
		passwordConfirmationErrors() {
			return this.password != this.password_confirmation
				? 'Password did not match'
				: this.errors.password_confirmation;
		},
		contactNumberErrors() {
			return this.errors.contact_number;
		},
		usernameErrors(){
			return this.specialChars.test(this.username) ? "Invalid Username"  : this.errors.username
		},
		addressErrors(){
			return !this.isAddressValid ? "Address can't be blank" : this.errors.address
		}
	},
	watch: {
		password(v) {
			if(v) this.errors = {}
		},
		password_confirmation(v){
			if(v) this.errors = {}
		},
		contact_number(v) {
			if(v) {
				this.errors = {}
				this.isContactValid = true;
			} else {
				this.isContactValid = false;
			}
		},
		default_currency(v){
			if(v) this.errors = {}
		},
		email(v){
			if(v) this.errors = {}
			if(v === this.old_email) this.isEmailNew = false;
			else this.isEmailNew = true;
		},
		username(v){
			if(v) this.errors = {}
		},
		first_name(v){
			if(v) this.errors = {}
		},
		last_name(v){
			if(v) this.errors = {}
		},
		address(v) {
			if(v) {
				this.errors = {} 
				if(v.trim().length === 0){
					this.isAddressValid  = false
				}
				else{
					this.isAddressValid = true;
				}
			}else{
				this.isAddressValid = true;
			}
		},

	},
};
</script>
